import { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import ApprovalIcon from '@mui/icons-material/Approval';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { useOpsUser, usePetByUser } from '@pumpkincare/users';

import RoutePaths from '../../../../routes';
import { addGroupDiscount, calculateGroupDiscount } from '../../../organizations';

function Discounts() {
  const { id } = useParams();
  const record = useRecordContext();

  const redirectTo = useRedirect();
  const notify = useNotify();

  const { data: currentOpsUserData, isLoading: isOpsUserLoading } = useOpsUser();
  const { data: petData, isLoading } = usePetByUser(id);

  // Remove the following console.log in the ticket FER-5376 and use petData
  console.log('petData', petData);
  const [passcode, setPasscode] = useState('');
  const [groupDiscounts, setGroupDiscounts] = useState([]);

  const petNames = record?.pets?.map(pet => pet.name);

  if (isLoading || isOpsUserLoading) {
    return <CircularProgress />;
  }

  function handleGroupDiscountClick() {
    calculateGroupDiscount({
      data: {
        user_id: record.id,
        customer_pkn: record.pumpkin_id,
        discount_passcode: passcode,
        pets: petNames,
      },
    })
      .then(response => {
        if (!response.data?.length) {
          notify('There is no discount to be applied to the customer pets.', {
            type: 'warning',
          });
        }
        setGroupDiscounts(response.data);
      })
      .catch(() => {
        notify('There was an error calculating group discount', {
          type: 'warning',
        });
      });
  }

  function handleAddDiscountClick() {
    const groupDiscountsFinal = groupDiscounts.map(item => ({
      user_id: currentOpsUserData.id,
      pet_id: item.pet_id,
      policy_id: item.policy_id,
      organization_id: item.organization_id,
      group_discount: item.group_discount,
      group_discount_percentage: item.group_discount_percentage,
      new_filed_rate: item.new_filed_rate,
      new_premium: item.new_premium,
      new_capped_rate: item.new_capped_rate,
    }));
    addGroupDiscount(groupDiscountsFinal)
      .then(response => {
        if (!response.error) {
          notify(response.message, { type: 'success' });
          redirectTo('show', `${RoutePaths.customers}/${id}/show`);
        } else {
          notify(response.message, { type: 'warning' });
        }
      })
      .catch(() => {
        notify('There was an error applying group discount', {
          type: 'warning',
        });
      });
  }

  return (
    <SimpleForm toolbar={null} onSubmit={null}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
      >
        <Grid item>
          <TextInput
            label='Passcode'
            source='passcode'
            value={passcode}
            onChange={event => setPasscode(event.target.value)}
          />
        </Grid>
      </Grid>
      {
        // The following code will be updated in the ticket FER-5376
      }
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
      >
        <Grid item>
          <Typography variant='h6'>
            Pet Names: {petNames.join(', ') || 'No pet names available'}
          </Typography>
        </Grid>
      </Grid>
      {
        // The code will be updated until now in the ticket FER-5376
      }
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
        sx={{ marginTop: '20px' }}
      >
        <Grid item>
          <Button
            variant='contained'
            color='secondary'
            label='Calculate'
            disabled={passcode === ''}
            onClick={() => {
              handleGroupDiscountClick();
            }}
            startIcon={<CalculateIcon />}
          >
            Calculate
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            label='Apply'
            disabled={!groupDiscounts.length}
            onClick={() => {
              handleAddDiscountClick();
            }}
            startIcon={<ApprovalIcon />}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </SimpleForm>
  );
}

export default Discounts;
