import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useAutomationSuggestions } from '@pumpkincare/claims';
import { ClaimCompletedDatagrid, ClaimLineItemGrid } from '@pumpkincare/shared';

import {
  DENIED,
  MGA_APPROVED,
  NEW_LINE_ITEM,
} from '../../../lib/claims/claim-status';
import ClaimAdjudicationDatagrid from '../claim-adjudication-datagrid';
import ClaimLineItemAutomationController from '../claim-line-item-automation';

DatagridSwitch.propTypes = {
  setRows: PropTypes.func,
  setPepRows: PropTypes.func,
  handleNextButtonclick: PropTypes.func,
  rows: PropTypes.array,
  pepRows: PropTypes.array,
  petData: PropTypes.object,
  policyData: PropTypes.object,
  wellnessData: PropTypes.object,
  onCommitCellChanges: PropTypes.func,
  onCommitLineItemChanges: PropTypes.func,
  toggleRowEditing: PropTypes.func,
  onCommitPepCellChanges: PropTypes.func,
  renderMoneyInput: PropTypes.func,
  lineItems: PropTypes.array,
  lineItemsByInvoiceData: PropTypes.object,
  isLineItemByInvoiceLoading: PropTypes.bool,
  onEditInvoiceClick: PropTypes.func,
  onLineItemChange: PropTypes.func,
  onLineItemAdd: PropTypes.func,
  onLineItemDelete: PropTypes.func,
  denialReasonData: PropTypes.array,
  insuranceDenialReason: PropTypes.array,
  preventProductsData: PropTypes.array,
  serviceData: PropTypes.array,
  incidentHistoryOptions: PropTypes.array,
  isLineItemRefetching: PropTypes.bool,
  currentOpsUserData: PropTypes.object,
  toggleOutsidePolicyPeriod: PropTypes.func,
};

const denied = DENIED.toLowerCase();
const approved = MGA_APPROVED.toLowerCase();
const new_line_item = NEW_LINE_ITEM.toLowerCase();

function getDecision(row, isRecommendedForPayment, suggestion) {
  if (!row.decision || row.decision === new_line_item) {
    if (isRecommendedForPayment) {
      const decisionWhenDenialReason = suggestion.denial_reason_id
        ? denied
        : row.decision;
      return suggestion.recommended_for_payment
        ? approved
        : decisionWhenDenialReason;
    }
  }
  return row.decision;
}

export function updateRow(
  row,
  automationSuggestions,
  serviceData,
  incidentHistoryOptions,
  fer5222PartialAutomation
) {
  const suggestion = automationSuggestions?.find(
    ({ claim_invoice_line_item_id }) =>
      claim_invoice_line_item_id === row.claim_invoice_line_item_id
  );

  if (!suggestion) {
    return row;
  }

  let service = row.service;

  if (
    !service &&
    (!suggestion.treatment_code_confidence ||
      suggestion.treatment_code_confidence > 0.8)
  ) {
    service = serviceData.find(({ id }) => id === suggestion.service_subcode_id);
  }

  if (!fer5222PartialAutomation) {
    return {
      ...row,
      service,
    };
  }

  const incident =
    row.incident_history ??
    incidentHistoryOptions.find(
      ({ value }) => value === suggestion.incident_history_id
    );

  const isRecommendedForPayment =
    typeof suggestion.recommended_for_payment === 'boolean';

  const decision = getDecision(row, isRecommendedForPayment, suggestion);

  const denial_reason =
    row.denial_reason ||
    (isRecommendedForPayment && !suggestion.recommended_for_payment
      ? [suggestion.denial_reason_id]
      : row.denial_reason);

  return {
    ...row,
    service,
    incident_history: incident ?? row.incident_history,
    incident: incident?.value ?? row.incident,
    incident_history_id: incident?.value ?? row.incident_history_id,
    decision,
    denial_reason,
  };
}

export default function DatagridSwitch(props) {
  const record = useRecordContext();
  const step = record?.step || 1;
  const [isReady, setIsReady] = useState(false);
  let insuranceLineItems;
  let preventLineItems;

  const { fer4611CasFrontendChanges, fer5222PartialAutomation } = useFlags();

  insuranceLineItems = record?.subclaims?.find(
    subclaim => subclaim.type === 'insurance'
  );

  preventLineItems = record?.subclaims?.find(
    subclaim => subclaim.type === 'prevent' || subclaim.type === 'wellness'
  );

  const {
    setRows,
    setPepRows,
    handleNextButtonclick,
    rows,
    pepRows,
    petData,
    policyData,
    wellnessData,
    onCommitCellChanges,
    onCommitLineItemChanges,
    toggleRowEditing,
    onCommitPepCellChanges,
    renderMoneyInput,
    lineItems,
    lineItemsByInvoiceData,
    isLineItemByInvoiceLoading,
    onEditInvoiceClick,
    onLineItemChange,
    onLineItemAdd,
    onLineItemDelete,
    denialReasonData,
    insuranceDenialReason,
    preventProductsData,
    serviceData,
    incidentHistoryOptions,
    isLineItemRefetching,
    currentOpsUserData,
    toggleOutsidePolicyPeriod,
  } = props;

  const { data: automationSuggestions } = useAutomationSuggestions(record?.id, {
    onSuccess: data => onAutomationSuggestionsSuccess(data),
    enabled: !!(
      fer4611CasFrontendChanges &&
      (isReady || step === 1) &&
      serviceData?.length &&
      incidentHistoryOptions?.length
    ),
  });

  useEffect(() => {
    if (record) {
      if (step >= 2) {
        if (insuranceLineItems?.line_items_decision) {
          setRows(
            insuranceLineItems.line_items_decision.map(item => {
              return {
                ...item,
                claimed_amount:
                  step <= 3 ? item.claimed_amount / 100 : item.claimed_amount,
                cnf_claim_id: record?.is_whitelabel ? record.cnf_claim_id : null,
                created_at: record?.is_whitelabel
                  ? insuranceLineItems.created_at
                  : null,
                date_completed: record?.is_whitelabel
                  ? insuranceLineItems.date_completed
                  : null,
                reimbursible_amount:
                  step <= 3
                    ? item.reimbursible_amount / 100
                    : item.reimbursible_amount,
                deductible_amount:
                  step <= 3 ? item.deductible_amount / 100 : item.deductible_amount,
                denial_reason:
                  item.denial_reason_items?.length && insuranceDenialReason.length
                    ? item.denial_reason_items.map(item => {
                        return insuranceDenialReason.find(denial => {
                          if (item.denial_reason_id === denial.id) {
                            return true;
                          }
                        })?.id;
                      })
                    : null,
                incident: item.incident_history?.id,
                service: item.service
                  ? {
                      name: item.service.subservice_name,
                    }
                  : null,
              };
            })
          );
        } else {
          setRows([]);
        }

        if (preventLineItems?.line_items_decision) {
          setPepRows(
            preventLineItems.line_items_decision.map(item => {
              return {
                ...item,
                claimed_amount:
                  step <= 3 ? item.claimed_amount / 100 : item.claimed_amount,
                approved_qty: item.approved_quantity,
                approved_amount:
                  step <= 3
                    ? item.reimbursible_amount / 100
                    : item.reimbursible_amount,
                declined_amount:
                  step <= 3
                    ? (item.claimed_amount - item.reimbursible_amount) / 100
                    : item.claimed_amount - item.reimbursible_amount,
                denial_reason: item.denial_reason_items?.length
                  ? item.denial_reason_items[0].denial_reason_id
                  : null,
              };
            })
          );
        } else {
          setPepRows([]);
        }

        setIsReady(true);
      }
    }
  }, [record, setRows, insuranceDenialReason.length]);

  function onAutomationSuggestionsSuccess(automationSuggestions) {
    if (automationSuggestions?.length) {
      const updatedRows = rows?.map(row =>
        updateRow(
          row,
          automationSuggestions,
          serviceData,
          incidentHistoryOptions,
          fer5222PartialAutomation
        )
      );

      setRows(updatedRows);
    }
  }

  switch (step) {
    case 1:
      return isLineItemByInvoiceLoading || isLineItemRefetching ? (
        <CircularProgress />
      ) : (
        <ClaimLineItemAutomationController
          lineItemsByInvoiceData={lineItemsByInvoiceData}
          onEditInvoiceClick={onEditInvoiceClick}
          onCommitLineItemChanges={onCommitLineItemChanges}
          petData={petData}
          policyData={policyData}
          wellnessData={wellnessData}
          renderMoneyInput={renderMoneyInput}
          currentOpsUserData={currentOpsUserData}
          toggleOutsidePolicyPeriod={toggleOutsidePolicyPeriod}
          automationSuggestions={automationSuggestions}
        />
      );

    case 2:
    case 3:
      return (
        <ClaimAdjudicationDatagrid
          rows={rows}
          pepRows={pepRows}
          setRows={setRows}
          handleNextButtonclick={handleNextButtonclick}
          setPepRows={setPepRows}
          toggleRowEditing={toggleRowEditing}
          onCommitCellChanges={onCommitCellChanges}
          onCommitPepCellChanges={onCommitPepCellChanges}
          renderMoneyInput={renderMoneyInput}
          insuranceLineItems={insuranceLineItems}
          preventLineItems={preventLineItems}
          denialReasonData={denialReasonData}
          insuranceDenialReason={insuranceDenialReason}
          preventProductsData={preventProductsData}
          serviceData={serviceData}
          incidentHistoryOptions={incidentHistoryOptions}
        />
      );
    case 4:
    case 5:
      return (
        <ClaimCompletedDatagrid
          rows={rows}
          pepRows={pepRows}
          insuranceLineItems={insuranceLineItems}
          preventLineItems={preventLineItems}
          incidentHistoryOptions={incidentHistoryOptions}
          preventProductsData={preventProductsData}
          isWhiteLabel={record?.is_whitelabel}
        />
      );
    default:
      return (
        <ClaimLineItemGrid
          onLineItemDelete={onLineItemDelete}
          onLineItemChange={onLineItemChange}
          onLineItemAdd={onLineItemAdd}
          lineItems={lineItems}
        />
      );
  }
}
